import * as Sentry from '@sentry/svelte'

// import Tracker from '@/util/tracker.js'

function isError(err, type, message) {
  if (err == null) {
    return false
  }
  if (err.name !== type) {
    return false
  }
  if (err.message == null) {
    return message == null
  }

  if (typeof message === 'string' && err.message === message) {
    return true
  }
  if (message instanceof RegExp && err.message.match(message)) {
    return true
  }

  return false
}

if (window.upsellery.environment !== 'test') {
  const integrations = [
    Sentry.sessionTimingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: [
        'error',
        ...(window.upsellery.environment !== 'production' ? ['warn'] : []),
      ],
    }),
    Sentry.contextLinesIntegration(),
    Sentry.extraErrorDataIntegration({ captureErrorCause: true }),
    Sentry.httpClientIntegration(),
  ]
  if (window.upsellery.environment !== 'development') {
    integrations.push(
      Sentry.replayIntegration({
        mask: ['.payment'],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    )
  }

  Sentry.init({
    release: window.upsellery.release,
    environment: window.upsellery.environment,
    dsn: 'https://bb56d992c2034854b7c6bb6180fa726a@o430515.ingest.sentry.io/6232547',
    integrations,
    sendDefaultPii: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    replaysSessionSampleRate: 0.02,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      'ChunkLoadError',
      'Failed to fetch',
      'Pe.sendBeacon(i18n/pixel/events)',
      /^NetworkError when attempting to fetch resource\./,
      "Cannot read properties of undefined (reading 'domInteractive')",
    ],
    denyUrls: [
      /(r\.)?beacon\((async-api|nr-spa-)/i,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /i18n\/pixel\/static\/main/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /^resource:\/\//i,
      /bundle\.fixdapp\.com/,
    ],

    beforeSend(event, hint) {
      // Disabled because it breaks the tracking retry. Commenting in
      // case it's necessary later.
      // Tracker.track('frontend Sentry error', { event })

      const err = hint.originalException
      if (isError(err, 'TypeError', /^Load failed\.?$/)) {
        return null
      }
      return event
    },
  })

  if (window.upsellery?.visitorUUID != null) {
    Sentry.setUser({ id: window.upsellery.visitorUUID })
  }
}
